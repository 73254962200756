import React from "react"
import Content from "../../components/Content"
import HeroImage from "../../components/HeroImage"
import Layout from "../../components/Layout"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

export default function Kontakt({ data }) {
  let createMarkup = () => {
    return { __html: data.allWpPage.nodes[0].content }
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {data.allWpPage.nodes[0].title} — Schülerwohnheim Forster GmbH
        </title>
      </Helmet>
      <HeroImage color="#fafe66" title={data.allWpPage.nodes[0].title} />
      <Content bgColor="#f6f49c">
        <div
          className="content container"
          style={{ fontSize: "20px" }}
          dangerouslySetInnerHTML={createMarkup()}
        />
      </Content>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDo3ODM=" } }) {
      nodes {
        title
        id
        content
      }
    }
  }
`
